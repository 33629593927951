/* eslint-disable */
/*
 * Module shim for rollup.js to work with.
 * Simply re-export Janus from janus.js, the real 'magic' is in the rollup config.
 *
 * Since this counts as 'autogenerated' code, ESLint is instructed to ignore the contents of this file when linting your project.
 */

@JANUS_CODE@

export default Janus;
